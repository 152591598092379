// -------------------------------------
// Headroom
// -------------------------------------
(function($) {
	$(function() {

		var $navbar = $("#mainNavbar");

		if ($navbar.length) {

			var $bgcolor = $navbar.css("background-color");
			var $offset = $navbar.offset();

			function setNavbarParentHeight() {
				var $wrapper = $navbar.parent();
				var $height = $("#mainNavbar").outerHeight();
				// $navbar.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
				// 	function(e) {

				$wrapper.css({
					"min-height": $height,
					// "background-color": $bgcolor,
				});

				// });

			}

			if ($navbar.hasClass(".navbar-expand-sm")) {
				var breakpoint = "576px";
			} else if ($navbar.hasClass(".navbar-expand-md")) {
				var breakpoint = "767px";
			} else if ($navbar.hasClass(".navbar-expand-lg")) {
				var breakpoint = "991px";
			} else {
				// Fallback
				var breakpoint = "767px";
			}

			// Set height and background color of wrapper
			// $(window).on("resize", function() {
			// 	setNavbarParentHeight();
			// }).resize();

			var options = {
				// offset: $offset.top,
				offset: 0,
				// tolerance: $height,
				classes: {
					// when element is initialised
					initial: "navbar_headroom",
					// when scrolling up
					pinned: "navbar_pinned",
					// when scrolling down
					unpinned: "navbar_unpinned",
					// when above offset
					top: "navbar_top",
					// when below offset
					notTop: "navbar_not_top",
					// when at bottom of scoll area
					bottom: "navbar_bottom",
					// when not at bottom of scroll area
					notBottom: "navbar_not_bottom"
				},
				// callback when pinned
				onPin: function() {},
				// callback when unpinned
				onUnpin: function() {},
				// callback when above offset
				onTop: function() {},
				// callback when below offset
				onNotTop: function() {},
				// callback at bottom of page
				onBottom: function() {},
				// callback when moving away from bottom of page
				onNotBottom: function() {}
			}

			if ($navbar.hasClass("navbar_sticky_mobile")) {
				enquire.register("screen and (max-width:" + breakpoint + ")", {
					match: function() {
						setNavbarParentHeight();
						$navbar.headroom(options);
					},
					unmatch: function() {
						setNavbarParentHeight();
						$navbar.headroom("destroy");
					},
					setup: function() {
						setNavbarParentHeight();
						$navbar.headroom(options);
					},
					destroy: function() {
						setNavbarParentHeight();
						$navbar.headroom("destroy");
					},
					deferSetup: true
				});

			} else {
				if ($navbar.hasClass("navbar_sticky")) {
					setNavbarParentHeight();
					$navbar.headroom(options);
				}
			}
		}

	});
})(jQuery);