(function($) {
	$(function() {

		var $collapseDrag = $(".drawer-draggable");

		if ($collapseDrag.length) {
			var $handle = $(".drawer-handle");
			var $overlay = $(".ui-mask");
			var $hamburger = $(".hamburger");

			var $collapseDragWidth = $collapseDrag.outerWidth();
			var $finishThreshold = $collapseDragWidth / 3;

			// Drag LEFT
			$collapseDrag.hammer({
				threshold: 0
			}).on("panleft", function(e) {
				var deltaX = e.gesture.deltaX;
				// console.log("Dragging left: " + deltaX);
				// Disable scrolling while dragging
				e.preventDefault();
				e.stopPropagation();
				// Remove transitions to prevent delays
				$collapseDrag.removeClass("transitioning");
				$("body").addClass("no-scrolling");
				if ($collapseDrag.hasClass("show")) {
					// showed
					if ((deltaX > -$collapseDragWidth) && deltaX < 0) {
						$(this).css("transform", "translate3d(" + (deltaX) + "px, 0px, 0px)");
						// console.log("Dragging left while showing: " + deltaX);
					}
				} else {
					// Closed
					// if ((deltaX > -$collapseDragWidth) && deltaX < 0) {
					$(this).css("transform", "translate3d(" + (deltaX - $collapseDragWidth) + "px, 0px, 0px)");
					// }
					// console.log("Left boundary reached");
				}
			});

			// Drag RIGHT
			$collapseDrag.hammer({
				threshold: 0
			}).on("panright", function(e) {
				var deltaX = e.gesture.deltaX;
				// console.log("Dragging right: " + deltaX);
				// Disable scrolling while dragging
				e.preventDefault();
				e.stopPropagation();
				// Remove transitions to prevent delays
				$collapseDrag.removeClass("transitioning");
				$("body").addClass("no-scrolling");
				if ($collapseDrag.hasClass("show")) {
					// showed
					if (deltaX < 0) {
						$(this).css("transform", "translate3d(" + (deltaX) + "px, 0px, 0px)");
						// console.log("Dragging right while show: " + deltaX);
					} else {
						$(this).addClass("transitioning").css("transform", "");
						// console.log("Right boundary reached");
					}
				} else {
					// Closed
					if (deltaX > 0 && deltaX < $collapseDragWidth) {
						$(this).css("transform", "translate3d(" + (deltaX - $collapseDragWidth) + "px, 0px, 0px)");
						// console.log("Dragging right while closed: " + deltaX + " - " + $collapseDragWidth);
					}
				}
			});

			// When over threshold finish showing or closing
			$collapseDrag.hammer().on("panend", function(e) {
				var deltaX = e.gesture.deltaX;
				// console.log("Stopped dragging");
				if (deltaX <= -$finishThreshold) {
					// Close
					$(this).addClass("transitioning").css("transform", "").removeClass("show");
					$overlay.removeClass("show");
					$hamburger.removeClass("is-active");
					$("body").removeClass("no-scrolling");
					// console.log("Closing nav");
				} else if (deltaX > $finishThreshold) {
					// show
					$(this).addClass("transitioning").css("transform", "").addClass("show");
					$overlay.addClass("show");
					$hamburger.addClass("is-active");
					$("body").addClass("no-scrolling");
					// console.log("showing nav");
				} else {
					// Reset
					$(this).addClass("transitioning").css("transform", "");
					// console.log("Did not drag far enough, resetting");
				}
			});

		}

	});
})(jQuery);