(function($) {

	var $open = $(".navbar .form-inline .search-open");
	var $close = $(".navbar .form-inline .search-close");
	var $input = $(".navbar .form-inline input");
	var $container = $(".navbar .form-inline .input-group");

	$open.on("click", function(e) {
		if ($container.hasClass("hidden")) {
			e.preventDefault();
			e.stopPropagation();
			$container.removeClass("hidden");
			$input.focus();
			$(".ui-mask").addClass("show");
			return false;
		}
	});

	$close.on("click", function(e) {
		e.preventDefault();
		e.stopPropagation();
		$container.addClass("hidden");
		$input.blur();
		$(".ui-mask").removeClass("show");
		return false;
	});

	$(".ui-mask").on("click", function(e) {
		e.preventDefault();
		e.stopPropagation();
		$container.addClass("hidden");
		$input.blur();
		$(".ui-mask").removeClass("show");
		return false;
	});

})(jQuery);