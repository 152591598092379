(function($) {
	$(function() {

		var $offcanvasSwipe = $(".drawer-swipable");
		var $collapseSwipe = $(".drawer-swipable");
		var $navbar = $("#mainNavbar");

		if ($offcanvasSwipe.length) {
			var $overlay = $(".ui-mask");
			var $hamburger = $(".hamburger");

			// Swipe to show
			$offcanvasSwipe.hammer().on("swiperight", function(e) {
				$(this).addClass("transitioning").addClass("show");
				$overlay.addClass("show");
				$hamburger.addClass("is-active");
				$("body").addClass("no-scrolling");
				// console.log("show nav");
			});

			// Swipe to close
			$offcanvasSwipe.hammer().on("swipeleft", function(e) {
				$(this).addClass("transitioning").removeClass("show");
				$overlay.removeClass("show");
				$hamburger.removeClass("is-active");
				$("body").removeClass("no-scrolling");
				// console.log("Close nav");
			});

		} else if ($collapseSwipe.length) {
			var $overlay = $(".ui-mask");
			var $hamburger = $(".hamburger");

			// FIXME: Vertical swipes don't work

			// Swipe down to show
			$navbar.hammer({
				direction: "DIRECTION_VERTICAL"
			}).on("swiperight", function(e) {
				$collapseSwipe.addClass("transitioning").collapse("show");
				$hamburger.addClass("is-active");
				console.log("show nav");
			});
			$navbar.hammer()
				.data("hammer")
				.get("swipe")
				.set({
					direction: Hammer.DIRECTION_VERTICAL
				});
			// $navbar.get("swipe").set({
			//   direction: Hammer.DIRECTION_VERTICAL
			// });


			// Swipe up to close
			$collapseSwipe.hammer({
				direction: "DIRECTION_VERTICAL"
			}).on("swipeleft", function(e) {
				$(this).addClass("transitioning").collapse("hide");
				$hamburger.removeClass("is-active");
				console.log("Close nav");
			});
			$collapseSwipe.hammer()
				.data("hammer")
				.get("swipe")
				.set({
					direction: Hammer.DIRECTION_VERTICAL
				});
			// $collapseSwipe.get("swipe").set({
			//   direction: Hammer.DIRECTION_VERTICAL
			// });

		}

	});
})(jQuery);