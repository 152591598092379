// -------------------------------------
// SlickSlider
// -------------------------------------
(function($) {
	var $slider = $(".slider");

	// Slider
	if ($slider.length) {
		$slider.on("init", function(event, slick) {
			$slider.find(".slide").matchHeight();
		});

		// Slick Slider
		$slider.slick({
			arrows: false,
			autoplay: true,
			autoplaySpeed: 10000,
			dots: true,
			draggable: true,
			focusOnSelect: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerPadding: 0,
		});

	}

})(jQuery);