// -------------------------------------
// Hamburger Active
// -------------------------------------
(function($) {
	$(function() {
		"use strict"

		var $hamburger = $(".hamburger");
		$hamburger.on("click", function(e) {
			if ($(this).closest().hasClass("collapsing")) {
				// Do nothing
			} else {
				$(this).toggleClass("is-active");
			}
		});

	});
})(jQuery);