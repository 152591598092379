// -------------------------------------
// Sticky Elements
// -------------------------------------
(function($) {
	$(function() {

		var $adminbar = $("#wpadminbar");
		var $navbar = $("#mainNavbar");

		var $stickyElement = $(".sticky-element");
		var $offsetTop = 15;

		if ($adminbar.length) {
			$offsetTop = $offsetTop + $adminbar.height();
		}

		if ($navbar.length) {
			$offsetTop = $offsetTop + $navbar.outerHeight();
		}

		if ($stickyElement.length) {
			$stickyElement.css({
				"top": $offsetTop + 'px',
			});
		}

	});
})(jQuery);