// -------------------------------------
// Drawer
// -------------------------------------
(function($) {
	$(function() {

		"use strict"

		var $navbar = $("#mainNavbar");
		var $adminbar = $("#wpadminbar");
		var $drawer = $(".drawer");
		var $offset = $navbar.outerHeight();

		if ($drawer.length) {

			// show / Close when clicking toggle
			$navbar.find(".navbar-toggler").on("click", function() {
				$drawer.toggleClass("show");
				$drawer.addClass("transitioning").css("transform", "");
				if ($drawer.hasClass("show")) {
					$drawer.removeClass("hide");
				} else {
					$drawer.addClass("hide");
				}
				$(".ui-mask").toggleClass("show");
				$("body").toggleClass("no-scrolling");
				setTimeout(function() {
					$drawer.removeClass("transitioning");
				}, 300);

			});

			// Change offset on resize
			$(window).on("resize touchmove", function() {
				if ($adminbar.length) {
					$offset = $navbar.outerHeight() + $adminbar.outerHeight();
				} else {
					$offset = $navbar.outerHeight();
				}
				$drawer.css({
					"top": $offset,
				});
			}).resize();

			$(".ui-mask").on("click", function() {
				$drawer.addClass("transitioning").removeClass("show").css("transform", "");
				$(".ui-mask").removeClass("show");
				$navbar.find(".hamburger").removeClass("is-active");
				$("body").removeClass("no-scrolling");
				setTimeout(function() {
					$drawer.removeClass("transitioning");
				}, 250);
			});

			$("body.no-scrolling").on("touchstart touchmove", function() {
				// Disable scrolling
				t.preventDefault();
				e.stopPropagation();
			});

		}

	});
})(jQuery);