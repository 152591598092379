// -------------------------------------
// Select 2
// -------------------------------------
(function($) {
	$(function() {

		var $select = $("select.custom-select2");

		if ($select.length) {

			var $select2 = $select.select2({
				minimumResultsForSearch: Infinity,
				// dropdownParent: $(this)
			});
			$select2.data("select2").$container.addClass("w-100");

			$select2.on("select2:closing"),
				function(e) {
					e.preventDefault();
				}

			// Slide down on open
			$select2.on("select2:open", function(e) {
				$(".select2-dropdown--below").stop(true, true).hide().slideDown(300);
				// $(".select2-dropdown--above").stop(true, true).hide().slideDown(300);
			});



			// Slide up on close
			$select2.on("select2:close", function(e) {
				$(".select2-dropdown").stop(true, true).show().slideUp(200);
			});

			$select.on("select2:select", function(e) {
				var data = e.params.data;
				console.log(data);
			});

		}

	});
})(jQuery);