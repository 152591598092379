// -------------------------------------
// Tooltips
// -------------------------------------
(function($) {
	$(function() {

		// Initiate tooltip on elements with data-toggle="tooltip"
		// $("[data-toggle=\"tooltip\"]").tooltip();

	});
})(jQuery);