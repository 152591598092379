// -------------------------------------
// Alerts
// -------------------------------------
(function($) {
	$(function() {

		// Dismissable alerts
		$(".alert").alert();

	});
})(jQuery);